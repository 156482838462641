<template>
  <div style="width: 100%">
    <div
      v-if="!id"
      class="policy-read"
      v-html="pageHtml"
    />
    <div
      v-if="id && type === 2"
      class="policy-read"
      v-html="pageHtml"
    />
    <div
      v-if="id && type === 1 && fileType === 2"
      ref="word"
      class="word"
    />
    <iframe
      v-if="id && type === 1 && fileType === 1"
      ref="pdf"
      :src="previewUrl"
      width="100%"
    />
  </div>
</template>

<script>
import { renderAsync } from 'docx-preview'
import { PreviewByContent, PreviewByFile } from '@/api/enterprise/prolicy'
import { getEnterpriseInfo } from '@/api/enterprise/enterprise'

window.JSZip = require('jszip')

export default {
  data() {
    return {
      pageH: `${document.body.clientHeight}px`,
      accessToken: localStorage.getItem('accessToken'),
      id: this.$route.query.id,
      type: Number(this.$route.query.t),
      iframeSrc: '',
      pageHtml: '',
      appName: '',
      previewUrl: '',
      fileType: 1, // 1 pdf 2 word
    }
  },
  created() {
    document.body.className = 'bg-white'
    this.getEnterpriseInfo()
    if (this.id) {
      if (this.type === 1) {
        this.previewByFile()
      } else {
        this.previewByContent()
      }
    } else {
      this.pageHtml = localStorage.getItem('htmlStr')
    }
  },
  methods: {
    getEnterpriseInfo() {
      getEnterpriseInfo().then(res => {
        const enterpriseInfo = { ...res.data.data }
        this.appName = enterpriseInfo.shortName
        document.title = `${this.appName}— 个人信息合规管理平台`
      })
    },
    previewByContent() {
      PreviewByContent(this.$route.query.id).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.pageHtml = resData.data
        }
      })
    },
    previewByFile() {
      PreviewByFile(this.$route.query.id).then(res => {
        const resData = res.data
        // renderAsync(resData, this.$refs.word)
        const fileType = resData.type
        if (fileType === 'application/octet-stream') {
          this.fileType = 2
          this.$nextTick(() => {
            renderAsync(resData, this.$refs.word)
          })
        } else {
          this.fileType = 1
          this.previewUrl = window.URL.createObjectURL(
            new Blob([resData], { type: 'application/pdf' }),
          )
          this.$nextTick(() => {
            this.$refs.pdf.height = document.documentElement.clientHeight
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.bg-white {
  background: #ffffff !important;
}
.policy-read {
  width: 1200px;
  margin: 20px auto 0 auto;
  table {
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
    border-spacing: 0;
    page-break-inside: avoid !important;
    border-collapse: collapse;
  }
  td,
  th {
    box-sizing: border-box;
    border: 1px solid #666666;
    page-break-inside: avoid !important;
  }
}
</style>
